const { useAtom } = require("jotai");
const { atomWithStorage } = require("jotai/utils");

const AuthAtom = atomWithStorage("crytoappfrank_auth", {
  email: "",
  accessToken: "",
  id: null,
});

export const useAuthAtom = () => useAtom(AuthAtom);

// GA
import ReactGA from "react-ga4";

// utils
import { lazy, Suspense } from "react";

// styles
import ThemeStyles from "@styles/theme";
import "./style.scss";
import "react-toastify/dist/ReactToastify.min.css";
import "react-grid-layout/css/styles.css";

// contexts
import { SidebarProvider } from "@contexts/sidebarContext";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "@contexts/modalContext";

// hooks
import { useThemeProvider } from "@contexts/themeContext";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";

// components
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "@components/Loader";
import Sidebar from "@components/Sidebar";
import BottomNav from "@components/BottomNav";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useAuth } from "@hooks/useAuth";
import { routes } from "routes";
import { SnackbarProvider } from "notistack";
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Trade = lazy(() => import("@pages/Trade"));
const Actions = lazy(() => import("@pages/Actions"));
const Wallet = lazy(() => import("@pages/Wallet"));
const NFT = lazy(() => import("@pages/NFT"));
const Collections = lazy(() => import("@pages/Collections"));
const SignIn = lazy(() => import("@pages/SignIn"));
const SignUp = lazy(() => import("@pages/SignUp"));
const Verification = lazy(() => import("@pages/Verification"));

const App = () => {
  const appRef = useRef(null);
  const { theme } = useThemeProvider();
  const { width } = useWindowSize();
  const { token } = useAuth();
  const queryClient = new QueryClient();
  console.log(process.env.APP_BASE_URL);
  useEffect(() => {
    appRef.current && appRef.current.scrollTo(0, 0);
  }, []);

  const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
  gaKey && ReactGA.initialize(gaKey);

  return (
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <SidebarProvider>
          <ThemeProvider theme={{ theme: theme }}>
            <ThemeStyles />
            <ToastContainer
              theme="colored"
              autoClose={2000}
              toastStyle={{ borderRadius: 4 }}
            />
            <ModalProvider>
              <div ref={appRef}>
                {width < 768 ? <BottomNav /> : <Sidebar />}
                <Suspense fallback={<Loader visible />}>
                  <Routes>
                    {token ? (
                      <>
                        <Route path={routes.home} element={<Dashboard />} />
                        <Route path={routes.actions} element={<Actions />} />
                        <Route path={routes.wallet} element={<Wallet />} />
                        <Route path={routes.trade} element={<Trade />} />
                        <Route path={routes.nft} element={<NFT />} />
                        <Route
                          path={routes.collections}
                          element={<Collections />}
                        />
                      </>
                    ) : (
                      <>
                        <Route path={routes.home} element={<SignIn />} />
                        <Route path={routes.signUp} element={<SignUp />} />
                      </>
                    )}
                  </Routes>
                </Suspense>
              </div>
            </ModalProvider>
          </ThemeProvider>
        </SidebarProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
};

export default App;

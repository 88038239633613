import { APP_BASE_URL } from "@constants/global";
import axios from "axios";
const instance = axios.create({
  baseURL: APP_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

export default instance;

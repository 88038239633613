import { useAuthAtom } from "store/auth";
import httpSecured from ".";
import httpNonSecured from ".";
import { useNavigate } from "react-router-dom";
export const useHttpRequest = () => {
  const navigate = useNavigate();
  const [auth] = useAuthAtom();
  httpSecured.interceptors.request.use(
    (config) => {
      if (auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor to handle errors
  httpSecured.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // Check for 401 error and handle token refresh
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Prevent infinite retry loop

        navigate("/");
      }

      const customError = new Error(
        error.response?.data?.message ||
          "An error occurred. Please try again later."
      );

      customError.status = error.response?.status;

      return Promise.reject(customError);
    }
  );

  return { httpSecured, httpNonSecured };
};

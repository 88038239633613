import { useHttpRequest } from "@hooks/axios/useAxios";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const walletQueryKeys = {
  GET_WALLETS: ["GET_WALLETS"],
};

export const notificationsKeys = {
  GET_NOTIFICATIONS: ["GET_NOTIFICATIONS"],
};

const getWallet = async ({ http }) => {
  try {
    const response = await http.get("/wallets");
    return response.data ?? null;
  } catch (error) {
    throw error;
  }
};

const getNotifications = async ({ http }) => {
  try {
    const response = await http.get("/notifications");
    return response.data ?? null;
  } catch (error) {
    throw error;
  }
};

export const useGetUsersWallets = () => {
  const { httpSecured } = useHttpRequest();
  return useQuery({
    queryFn: async () => getWallet({ http: httpSecured }),
    queryKey: walletQueryKeys.GET_WALLETS,
    enabled: true,
    staleTime: 0,
  });
};

export const useGetUsersNotifications = () => {
  const { httpSecured } = useHttpRequest();
  return useQuery({
    queryFn: async () => getNotifications({ http: httpSecured }),
    queryKey: notificationsKeys.GET_NOTIFICATIONS,
    enabled: true,
    staleTime: 0,
  });
};

export const useGetUsdValues = () => {
  const { httpSecured } = useHttpRequest();
  const getUsdValue = async (id) => {
    const result = await httpSecured.get(`/wallets/currency/${id}/convert`);

    return result?.data;
  };

  return { getUsdValue };
};

export const routes = {
  home: "/",
  trade: "/trade",
  actions: "/actions",
  wallet: "/wallet",
  nft: "/nft",
  collections: "/collections",
  signIn: "/sign-in",
  signUp: "/sign-up",
  verification: "/verification",
};
